import React from 'react'

import { AboutWrapper, Content, ImageContainer, DownloadImages } from './styles'

const AboutContent = ({ data }) => {
  const {
    frontmatter: {
      title,
      image: {
        childImageSharp: { fluid }
      }
    },
    html
  } = data

  return (
    <AboutWrapper>
      <Content>
        <h1>{title}</h1>
        <div dangerouslySetInnerHTML={{ __html: html }} />
        <DownloadImages>
          <p>
            Click <a href="/PROMO.zip">here</a> to download press image folder.
          </p>
        </DownloadImages>
      </Content>
      <ImageContainer bgImage={fluid.src} />
    </AboutWrapper>
  )
}

export default AboutContent
