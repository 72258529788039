import React from 'react'
import { graphql } from 'gatsby'

import SEO from 'components/seo'
import AboutContent from 'components/About'

const AboutTemplate = ({ data }) => {
  const { markdownRemark } = data
  return (
    <>
      <SEO title="About" />
      <AboutContent data={markdownRemark} />
    </>
  )
}

export default AboutTemplate

export const query = graphql`
  query About($locale: String!, $title: String!) {
    markdownRemark(frontmatter: { title: { eq: $title } }, fields: { locale: { eq: $locale } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      html
    }
  }
`
