import styled from 'styled-components'

export const AboutWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-top: 13rem;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    height: auto;
  }
`

export const Content = styled.div`
  height: 100vh;
  width: 100%;
  max-width: 570px;
  padding: 0 3rem 3rem 3rem;
  text-align: justify;

  h1 {
    color: ${({ theme }) => theme.color.black};
    font-size: ${({ theme }) => theme.fontSize['5xl']};
    font-weight: ${({ theme }) => theme.fontWeight['bold']};
    line-height: ${({ theme }) => theme.lineHeight['none']};
    margin-bottom: 1.25rem;
  }

  p {
    color: ${({ theme }) => theme.color.black};
    font-size: ${({ theme }) => theme.fontSize.sm};
    padding: 0.4rem 0;
  }

  a {
    color: ${({ theme }) => theme.color.vividBlue};
  }

  @media screen and (max-width: 1024px) {
    height: auto;
  }
`

export const ImageContainer = styled.div`
  width: 65%;
  background-image: url(${({ bgImage }) => bgImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  overflow: hidden;
  opacity: 0;
  animation: fadeIn 0.6s 0.3s cubic-bezier(0.19, 1, 0.22, 1) forwards;

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 300px;
  }

  @keyframes fadeIn {
    100% {
      opacity: 1;
    }
  }
`

export const DownloadImages = styled.div`
  margin: ${({ theme }) => theme.remToPx(40)} 0;

  @media screen and (max-width: 1024px) {
    text-align: center;
    margin-bottom: ${({ theme }) => theme.remToPx(10)};
  }
`
